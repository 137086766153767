import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(private router: Router,    private toastr: ToastrService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const xApiKey = "xPpKlU8Aoa120HtFhaHFg8ML6QtIFx0l6iSAR69b";
    const xAccessTtoken = sessionStorage.getItem('token');
    
    if (request.url.includes('/login')) {
      return next.handle(request);
    }

    let newHeaders = request.headers;
    if (xAccessTtoken && xApiKey) {
      newHeaders = newHeaders.append('Authorization', xAccessTtoken);
      newHeaders = newHeaders.append('x-api-key', xApiKey);
    } else {
      this.router.navigate(['/login']);
    }

    const authReq = request.clone({ headers: newHeaders });

    return next.handle(authReq).pipe(catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          sessionStorage.clear();
          this.router.navigate(['/login']);
          this.toastr.error('Your session has expired. Please log in again.');
        }
        return throwError(() => error);
      })
    );
  }
}
